
h1 {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #ffffff;
    font-size: 28px;
    margin: 0;
    font-weight: bold;
}

.container-info {
    display: flex;
    gap: 20px;
    padding: 0;  /* Removed unnecessary padding */
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.channel-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #e0e0e0;
    width: 30%;
    flex-shrink: 0;
    /* padding: 0;  */
    padding: 0px 20px;
}

.channel-logo {
    width: 80px;
    height: 80px;
    background-color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
}

.show-image {
    width: 100%;
    height: 200px;
    background-color: #666;
    position: relative;
    padding: 0;  /* Removed extra padding */
}

.show-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.show-image::after {
    content: "Press OK to watch";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(111 110 110 / 46%);
    color: #fff;
    font-size: 14px;
    text-align: start;
    padding: 15px;
}

.show-time {
    width: 100%;
    color: #fff;
    font-size: 16px;
    text-align: start;
    padding: 10px 0;  /* Adjust padding to be more consistent */
    margin-left: 8px;
}

.programme-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    min-width: 300px;
    overflow: auto;
    width: 65%;
    padding: 0; /* Removed extra padding */
}

.programme-title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
}

.programme-meta h3 {
    font-size: 14px;
    color: #cccccc;
    margin-bottom: 15px;
}

.programme-description h2 {
    font-size: 20px;
    line-height: 1.6;
    color: #d4d4d4;
    margin-bottom: 20px;
    max-height: 100%;
    overflow: auto;
}

.icons {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
}

.icon {
    width: 24px;
    height: 24px;
    background-color: #888;
    border-radius: 4px;
}

.watch-room-button {
    display: inline-block;
    background-color: #ffffff;
    color: #1a1a1a;
    padding: 19px 109px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background 0.3s;
    margin-top: 10px;
}

.watch-room-button:hover {
    background-color: #cccccc;
}

.channel-info_new {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    width: 100%;
    padding: 0;  /* Removed extra padding */
}

.channel-logo_new {
    width: 50px;
    height: 50px;
    background-color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
}

.channel-text_new {
    color: #e0e0e0;
    font-size: 18px;
}

.channel-number_new {
    color: #888;
    font-size: 14px;
}

/* Ensure content fits well on smaller screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .channel-info {
        width: 100%;
        margin-bottom: 20px;
    }

    .programme-details {
        width: 100%;
    }
}

/* Header Styles */
.programme-header {
    width: 100%;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    padding: 15px 0;
    margin-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 0px 8px;
}

/* Responsive Header */
@media (max-width: 768px) {
    .programme-header {
        font-size: 20px;
        padding: 10px 0;
    }
}
