.video-ad-banner {
    position: relative;
    text-align: center;
    display: inline-block;
    /* width: 100%;  */
    width: 65%; 
    max-width: 640px; /* Set a max width if needed */
    margin: 0 auto; /* Center the video ad */
  }
  
  .video-ad-banner video {
    width: 100%;
    height: auto;
  }
  
  .skip-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .skip-button:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  