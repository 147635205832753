.app-w {
    display: flex;
    min-height: 100vh;
    background-color: black;
    color: white;
  }
  
  .main-content {
    flex: 1;
    /* padding: 24px; */
    padding: 41px;

  }
  
  /* .room-title {
    font-weight: 500;
    margin-bottom: 32px;
  } */

  .room-title {
    font-weight: 500;
    margin-bottom: 32px;
    overflow: hidden; /* Ensures content doesn't overflow the container */
    text-overflow: ellipsis; /* Adds "..." at the end if the text is too long */
    white-space: nowrap; /* Prevents text from wrapping onto the next line */
    width: 100%; /* Ensures it works within the parent's width */
    display: block; /* Ensures the element behaves like a block for proper width management */
  }
  
  
  .sidebar {
    width: 320px;
    border-left: 1px solid #2d2d2d;
    /* padding: 24px; */
  }
  
  .chat-panel {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
  }
  
  .chat-panel-title {
    font-size: 1.125rem;
    margin-bottom: 16px;
  }
  
  .chat-panel-messages {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  
  .chat-panel-input {
    width: 100%;
    background-color: #2d2d2d;
    border-radius: 8px;
    padding: 12px;
    color: white;
    border: none;
  }
  
  .chat-message {
    display: flex;
    gap: 12px;
    align-items: start;
  }
  
  .chat-message-text {
    background-color: #2d2d2d;
    border-radius: 8px;
    padding: 12px;
    margin: 2px;

  }
  
  .group-list {
    margin-bottom: 32px;
  }
  
  .group-list-title {
    font-size: 1.125rem;
    margin-bottom: 16px;
  }
  
  .group-list-item {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .group-list-status.online {
    color: green;
    background:green;
    border-radius: 7px
  }
  
  .group-list-status.offline {
    color: gray;
    background:gray;
    border-radius: 7px
  }
  
  /* ShowInfo */
  /* .show-info {
    position: relative;
    min-height: 400px;
    border-radius: 12px;
    background-image: repeating-linear-gradient(90deg, rgba(139, 0, 0, 0.8) 0px, rgba(90, 0, 0, 0.8) 20px, rgba(139, 0, 0, 0.8) 40px);
    overflow: hidden;
  } */
  .show-info {
    position: relative;
    min-height: 400px;
    border-radius: 12px;
    background-image: url('https://watchparty.tpvai.com/b/uploads/bf1ecaec-d764-489d-9ebf-1b08fef491dd.jpg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    overflow: hidden;
  }
  
  
  .show-info-content {
    padding: 32px;
  }
  
  .qr-code-container {
    border-radius: 12px;
  }
  

  .user-avatar-small{
    background-color: rgb(236, 72, 153);
    width: 2.5rem; 
    height: 2.5rem; 
    border-radius: 9999px; 
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-shrink: 0;
    margin: 1px;
  }

  .user-avatar-medium{
  background-color: rgb(56 132 241);
  width: 2.5rem; 
  height: 2.5rem; 
  border-radius: 9999px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  flex-shrink: 0; 
  margin: 1px;
  }