.container {
  width: 100%;
  background-color: transparent;
  padding: 0;
}

.box {
  flex-grow: 1;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}

.grid-item {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}



.gridcenter{
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-container {
position:fixed;
bottom:0;
color:white;
padding-left:2%;
padding-right:2%;
padding-bottom:1%;
}

.card {
  background-color: rgba(255, 255, 255, 0.7);
}

.card-body {
  text-align: center;
}

.send-button {
  margin-bottom: 12px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.send-button img {
  width: 30px;
  height: 30px;
}

.send-button span {
  position: relative;
  top: 23px;
  right: 17px;
  font-size: 10px;
}

.chatitem{
    position: absolute;
    /* bottom: 130px; */
    bottom: 273px;
    /* width: 100%; */
    width: -webkit-fill-available;
}


.emoji-container {
  margin-top: 20px;
}

.emoji {
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
}

.floating-emoji-container {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.floating-emoji {
  font-size: 48px;
  margin-bottom: 10px;
  animation: floatUp 4s ease-in-out forwards;
}

@keyframes floatUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}


.autherusername{
  background: #000;
  width: min-content;
  color: #fff;
  padding: 3px;
  border-radius: 8px;
  font-size: 12px;
}



.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side,#0000 40%,#474bff 41%);
  background: linear-gradient(0deg ,rgba(71,75,255,0.5) 50%,rgba(71,75,255,1) 0) center/4.5px 100%,
       linear-gradient(90deg,rgba(71,75,255,0.25) 50%,rgba(71,75,255,0.75) 0) center/100% 4.5px;
  background-repeat: no-repeat;
  animation: spinner-d3o0rx 1s infinite steps(12);
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.spinner::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes spinner-d3o0rx {
  100% {
     transform: rotate(1turn);
  }
}

.loadercenter{
  display: flex;
  justify-content: center;
  padding: 35px;
}

.morecard{
  border: 1px solid #333;
  padding: 5px;
  border-radius: 22px;
  cursor: pointer;
  background-color: #ffde2b;
}

/* //remote button */
.circle-button-remote {
  /* width: 17px;
  height: 17px;
  border-radius: 50%; */
  width: 24px;
  height: 17px;
  border-radius: 24%;
  background-color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 8px;
  font-weight: bold;
  border: 2px solid #555;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

