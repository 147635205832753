/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #121212;
    color: #ffffff;
  } */


  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between elements */
    height: 60px; /* Header height */
    margin-left: 57px;
    background-color: #232323;
    color: #fff; /* White text */
    padding: 0 20px; /* Padding on left and right */
    font-size: 18px; /* Font size */
    font-weight: bold;
    border-bottom: 1px solid #444; /* Add border to separate header */
    z-index: 10; /* Ensure it stays on top */
  }
  
  .header-back-icon {
    margin-right: 15px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .header-title {
    flex-grow: 1;
    font-weight: bold;
    text-align: left;
  }

  .header-s {
    color: #fff;
    padding: 0 9px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #444;
    z-index: 10;
  }
  
  .header-back-icon-s {
    margin-right: 15px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .header-title-s {
    flex-grow: 1;
    font-weight: bold;
    text-align: left;
  }
  

.tv-guide {
    display: flex;
    /* height: 100vh; */
    height: calc(100vh - 60px);
    overflow: hidden;
  }
  
  .sidebar {
    width: 434px;
    background-color: #1c1c1c;
    border-right: 1px solid #333;
    /* overflow: hidden; */
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .sidebar-header {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: #1c1c1c;
    position: sticky;
    top: 0;
    z-index: 20;
    border-bottom: 1px solid #333;
  }
  
  .channel-container {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 10px;
    background-color: #1c1c1c;
  }
  
  .channel-container img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 4px;
  }
  
  .channel-container .channel-name {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .schedule {
    flex-grow: 1;
    overflow: auto;
  }
   /* .schedule {
  outline: none;
} */
  
  .time-bar {
    display: flex;
    /* background-color: #232323; */
    height: 40px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    min-width: 1200px;
    padding: 0 10px;
  }
  
  .time-bar div {
    flex: 1;
    text-align: center;
    font-size: 14px;
  }
  
  .channel-rows-container {
    display: flex;
    flex-direction: column;
  }
  
  .channel-row {
    display: flex;
    height: 60px;
    min-width: 1200px;
    padding: 1px;
  }
  
  .program-container {
    display: flex;
    flex: 1;
    gap: 2px;
  }
  
  .program {
    cursor: pointer;
    background-color: #2a2a2a;
    padding: 10px;
    font-size: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 50px;
    border: 1px solid #4a4a4a;
  }
  .program div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .program.selected {
    border: 2px solid #fff; /* White border when selected */
    background-color: rgba(255, 255, 255, 0.1); /* Optional: add a subtle background for selected programs */
  }
  
  .program span {
    margin-top: 5px;
    color: #a0a0a0;
  }
  
  .highlight {
    background-color: #ffffff;
    color: #000000;
    border-color: #888;
  }
  
  .scrollable-area {
    display: flex;
    /* overflow-x: auto;
    overflow-y: hidden; */
  }
  

  /* .time-indicator {
    position: absolute;
    width: 2px;
    background-color: #ff4444;
    height: 100%;
    top: 0;
    z-index: 10;
} */
.time-indicator {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: red;
  z-index: 10;
}


.icon-container_1 {
    position: relative;
    display: inline-block;
    /* background: rgb(85 85 85); */
    /* padding: 2px; */
    border-radius: 6px;
}

.tv-icon_1 {
    width: 50px;
    height: auto;
    display: block;
}

/* Top Left and Top Right Icons */
.icon-left_1, .icon-right_1 {
    position: absolute;
    font-size: 0.9em;
    color: #fff;
    /* background: rgba(0, 0, 0, 0.5); */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Icon Positions */
.icon-left_1 {
    top: -5px;
    left: -5px;
}

.icon-right_1 {
    top: -5px;
    right: -5px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full-screen height */
  font-size: 20px;
  font-weight: bold;
  color: #333;
}


.program.focused {
  outline: 2px solid rgb(255, 255, 255); /* Adjust as needed */
}



/* //Menu */
/* Sidebar menu container */


/* .sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #1c1c1c;
  color: #f1f1f1;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar-menu.open {
  transform: translateX(0);
}

.sidebar-content h2 {
  padding: 20px;
  font-size: 20px;
  font-weight: normal;
  color: #a1a1a1;
  margin: 0;
  border-bottom: 1px solid #333; 
}


.menu-options {
  list-style: none;
  padding: 0;
  margin: 0;
}


.menu-options li {
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.menu-options li:hover {
  background-color: #333;
}

.menu-options li.active {
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
} */



.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #1c1c1c;
  color: #f1f1f1;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

/* Sidebar when open */
.sidebar-menu.open {
  transform: translateX(0);
}

/* Menu heading */
.sidebar-content h2 {
  padding: 20px;
  font-size: 20px;
  font-weight: normal;
  color: #a1a1a1; /* Muted color for the heading */
  margin: 0;
  border-bottom: 1px solid #333; /* Optional divider line */
}

/* Menu items container */
.menu-options {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Menu items */
/* Menu items */
.menu-options li {
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for highlight */
.menu-options li:hover {
  background-color: #ffffff; /* Highlight background */
  color: #000000; /* Highlight text color */
  font-weight: bold;
}


.menu-options li.active {
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
}


