.ad-banner {
    position: relative;
    text-align: center;
    display: inline-block;
  }
  
  .ad-banner img {
    /* width: fit-content;
    height: 99px; */
    height: 49px;
    object-fit: cover;
    width: 238px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 10; 
    background-color: #fff;
    border-radius: 25px;
  }
  
  .close-button:hover {
    color: red;
  }
  