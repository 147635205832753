/* .app{

     background-image: url('../../public/images/view-3d-cinema-theatre-room.jpg');
     background-size: cover;
     background-repeat: no-repeat;
     background-attachment: fixed; 
     background-position: center;
     margin: 0;
     padding: 0; 
     overflow: hidden;
     min-height: 100vh;
     color: #e8e9ff;
     backdrop-filter: blur(3px);
} */


/* .app {
  background: #1a1a2e; 
  background-image: url('../../public/images/view-3d-cinema-theatre-room.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100vh;
  color: #e8e9ff;
  backdrop-filter: blur(3px);
  transition: background-image 0.5s ease-in-out;
} */

.app {
  background: #1a1a2e;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100vh;
  color: #e8e9ff;
  backdrop-filter: blur(3px);
  transition: background-image 0.5s ease-in-out; /* Smooth transition */
}

.low-quality {
  background-image: url('../../public/images/view-3d-cinema-theatre-room1_thumbnail_1920x1080.jpg'); /* Low-quality image */
}

.high-quality {
  background-image: url('../../public/images/view-3d-cinema-theatre-room.jpg'); /* High-quality image */
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 46%);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.plus-button {
  width: 20px;
  height: 20px;
  background-color: #ff9800; /* Orange color */
  border: none;
  border-radius: 50%; /* Makes it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
  cursor: pointer;
  position: relative; /* For inner elements alignment */
}

.plus-button:after,
.plus-button:before {
  content: '';
  position: absolute;
  background-color: white; /* White for the plus sign */
}

.plus-button:after {
  width: 60%; /* Horizontal bar of plus */
  height: 10%;
}

.plus-button:before {
  width: 10%; /* Vertical bar of plus */
  height: 60%;
}






.header {
    padding: 1rem;
    display: flex;
    align-items: center;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .logo {
    width: 40px;
    height: 40px;
    background-color: #4169E1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  
  h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .sidebar-watch {
    position: fixed;
    left: 0;
    width: 280px;
    height: 100%;
    padding: 1rem 1rem 1rem;
  }
  
  .sidebar-content-watch {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .sidebar-section--watch h2 {
    color: #a8aacc;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .tv-guide-btn {
    width: 114px;
    height: 92px;
    background-color: #4169E1;
    color: white;
    border: 3px solid #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s ease;
    padding: 0;
  }
  
  .tv-guide-btn:hover {
    background-color: #3158d3;
  }
  
  .menu-icon {
    font-size: 1.25rem;
  }
  
  .welcome-overlay {
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* padding: 2.5rem; */
    border-radius: 8px;
    text-align: center;
    /* max-width: 800px; */
    width: 90%;
  }
  
  .welcome-overlay h1 {
    font-size: 3rem;  
    color: #e8e9ff;   
    margin-bottom: 1rem;
  }
  
  
  .welcome-text {
    font-size: 31px;
    color: #c5c7e4;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .requirement-text {
    color: #8487a7;
    font-size: 21px;
    font-style: italic;
    margin: 0;
  }
  
  .main-content-grid {
    margin-left: 280px;
    padding: 1rem;
    min-height: calc(95vh - 72px);
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  
  
  .my-rooms,
  .joined-rooms {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  

    
   /* .my-rooms,
.joined-rooms {
  display: flex;
  gap: 1rem;
  overflow-x: auto; 
  white-space: nowrap; 
  padding: 0.5rem; 
  scrollbar-width: thin; 
  scrollbar-color: #888 transparent;
}

.my-rooms::-webkit-scrollbar,
.joined-rooms::-webkit-scrollbar {
  height: 8px; 
}

.my-rooms::-webkit-scrollbar-thumb,
.joined-rooms::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
}

.my-rooms::-webkit-scrollbar-track,
.joined-rooms::-webkit-scrollbar-track {
  background-color: transparent; 
} */

  
  .my-room-card {
    background-color: #585960;
  }
  
  .joined-room-card {
    background-color: rgba(40, 167, 69, 0.2);
  }
  
  .room-card {
    width: 197px;
    height: 109px;
    padding: 5px;
    color: #e8e9ff;
    border-radius: 6px;
    text-align: center;
    backdrop-filter: blur(3px);
    transition: background-color 0.3s ease;
  }
  
  
  .room-card:focus {
    outline: 2px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  
  .room-card:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .short-date {
    font-size: 1rem;
    color: white;
    text-align: center;
  }




  .welcome-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 45%;
        left: 58%;
        transform: translate(-50%, -50%);
        width: 85%;
        max-width: 650px;
        padding: 2rem;
        text-align: center;
  }
  
  .w-title {
    font-size: 2.8rem;
    color: #f5f5fa; /* Light text for better contrast */
    margin-bottom: 1.2rem;
    font-weight: bold;
  }
  
  .welcome-message {
    font-size: 1.25rem;
    color: #d1d1e3; /* Subtle light gray text */
    margin-bottom: 1.8rem;
    line-height: 1.6;
  }
  
  .welcome-requirements {
    font-size: 1rem;
    color: #fff; /* Muted italic text */
    font-style: italic;
    margin: 0;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .welcome-container {
      padding: 1.5rem;
      width: 90%;
    }
  
    .welcome-title {
      font-size: 2.2rem;
    }
  
    .welcome-message {
      font-size: 1rem;
    }
  
    .welcome-requirements {
      font-size: 0.85rem;
    }
  }
  